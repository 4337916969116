@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.numberForm {
  color: $body-bg;
  background-color: $dark-blue;
  box-shadow: 0px 3px 6px #00002929;
}

.optimal {
  background-color: $success;
}

input[type='number'] {
  width: 100%;
}
