@import 'variables.scss';

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.panelBlue {
  background-color: $secondary;
  color: $darker-blue;
  padding: 30px 40px;
}
