@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
}

.panelGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
}

.panel {
  color: white;
  padding: 30px 40px;
  min-height: 400px;
  max-width: 600px;
}

.panelOrange {
  background: $primary;
  color: white;
  padding: 30px 40px;
  min-height: 400px;
  max-width: 600px;
}

.panelBlue {
  background-color: $secondary;
  color: $darker-blue;
  padding: 30px 40px;
  min-height: 400px;
  max-width: 600px;
}

.formGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 3rem;

  & * {
    width: 100%;
    font-weight: 900;
  }
}

@include media-breakpoint-up(lg) {
  .panel {
    min-height: 600px;
  }

  .panelGrid {
    grid-template-columns: 1fr 1fr;
  }

  .formGrid {
    grid-template-columns: 1fr 1fr;
  }
}
