@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.loader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}

@include media-breakpoint-up(lg) {
  .loader {
    left: $drawerWidth;
    width: calc(100vw - #{$drawerWidth});
  }
}
