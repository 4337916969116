@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

p {
  margin-bottom: 0 !important;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
}

.noTreats {
  background-color: rgba(255, 255, 255, 0.85);
}

@include media-breakpoint-up(md) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
