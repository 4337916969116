@import 'variables.scss';

.numberForm {
  color: $body-bg;
  background-color: $dark-blue;
  box-shadow: 0px 3px 6px #00002929;

  .labelHelp {
    opacity: 0.69;
    margin-bottom: 0.5rem !important;
    line-height: 1.3rem;
  }

  input[type='number'] {
    width: 60%;
  }
}

.optimal {
  background-color: $success;
}

.bloodPressureContainer {
  margin: 0;
}

.bloodPressureScale {
  position: relative;
  display: flex;
  margin-right: 0; //calc(-100% / 13);
  margin-left: calc(100% / 13);

  & > .scaleNotch {
    width: calc(100% / 13);
    & > .bloodPressureScaleValue {
      transform: translate(-50%, 0);
      text-align: center;
      font-weight: 300;
      font-size: clamp(10px, 1em, 1vw);
    }
  }
}

.bloodPressureMeter {
  margin: 1.5rem 0 1.5rem 0;
  border-radius: 6em;
  width: 100%;
}

.resultValueContainer {
  position: absolute;
  width: calc(100% - (100% / 12));
  .resultValueContainerInner {
    position: relative;
    .resultValue {
      position: absolute;
      background-color: $success;
      color: $luh-white;
      border-radius: 100%;
      transform: translate(50%, -20%);
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      box-shadow: 0px 3px 6px #00000029;
      font-size: clamp(10px, 1em, 1vw);
    }
  }
}

.yinYang {
  font-size: clamp(12px, 1.5rem, 5vw);
}

.yinYangArrow {
  width: clamp(5vw, 300px, 20vw);
  height: 15px;
}

.red {
  color: $red;
}

.yellow {
  color: $yellow;
}

.green {
  color: $success;
}

.blue {
  color: $blue;
}
