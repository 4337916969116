@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import 'scss/buttons.scss';
@import 'scss/custom-scrollbars.scss';
@import 'scss/alerts.scss';

html,
body,
#root {
  height: 100%;
}

body {
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  color: $navy;
}

.sidenav .active {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background: $darker-blue;
  border-right: solid 4px $yellow;
}

.cursor-pointer {
  cursor: pointer;
}

.round-icon-button {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;

  &:hover {
    background: $dark;
    color: #fff;
    scale: 1.3;
    border: solid 2px $dark;
  }
}

.breadcrumb {
  background-color: white;
  margin: 0;
  padding: 0;

  & * {
    margin: 0;
    color: $dark-blue !important;
  }
}

.nav-tabs {
  & .nav-link {
    background-color: $dark-blue;
    color: white;
    border: none;

    &.active {
      background-color: $primary !important;
      color: white;
      border: none;
    }

    &:hover {
      background-color: $primary;
      color: white;
      border: none;
    }
  }
}

.list-group-item-action {
  background-color: $dark-blue;
  color: white;

  &:focus {
    background-color: $dark-blue;
    color: white;
  }

  &:hover {
    background-color: $primary;
    color: white;
  }
}

.background-secondary {
  background-color: $secondary !important;
}

.background-gradient {
  background: $background-gradient;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-dark-blue {
  background-color: $dark-blue;
  color: $luh-white;
}

.bg-red {
  background-color: $red;
  color: $luh-white;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-green {
  background-color: $success;
}

.bg-blue {
  background-color: $blue;
}

.text-black {
  color: black !important;
}

.text-orange {
  color: $orange !important;
}

.form-control:disabled {
  background-color: #dddddd;
}

.background-grey {
  background-color: $bg-grey;
}

.text-yellow {
  color: $yellow;
}

.text-red {
  color: $red;
}

.text-green {
  color: $success;
}

.text-blue {
  color: $blue;
}

.mb-100 {
  margin-bottom: 100px;
}

.w-200 {
  width: 200% !important;
}

.card-shadow {
  box-shadow: 0px 3px 6px #00002929;
}
