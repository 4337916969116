@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.logo {
  max-width: 240px;
}

@include media-breakpoint-up(lg) {
  .logo {
    max-width: 90vw;
  }
}
