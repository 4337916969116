@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.choiceForm {
  color: $body-bg;
  background-color: $dark-blue;
}

.optimal {
  background-color: $success;
}

.options {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-grow: 1;
  cursor: pointer;
}

.option {
  height: 100%;
  border-radius: 5px;
  color: $dark-blue;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  font-weight: 300;
}

.selected {
  background-color: $secondary;
}
