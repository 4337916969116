@import 'variables.scss';

.ScrollbarsCustom-Wrapper {
  inset: 0px 50px 0px 0px !important;
}

.ScrollbarsCustom-Track {
  overflow: visible !important;
  width: 8px !important;
}

.ScrollbarsCustom-Thumb {
  background: $orange !important;
  border-radius: 50% !important;
  width: 20px !important;
  margin-left: -6px;
}
