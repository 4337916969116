@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

p {
  margin-bottom: 0 !important;
}

.treatmentPanel {
  padding: 0;
  max-width: 600px;
}

.treatmentPanelHeader {
  padding: 4px 40px 4px 40px;
  height: 140px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;

  & * {
    font-weight: 900;
  }
}

.treatmentPanelContent {
  margin: 30px;
}
