@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
}

.panel {
  color: white;
  padding: 30px 40px;
  min-height: 400px;
  max-width: 600px;
}

.panelOrange {
  background: $primary;
  color: white;
  padding: 30px 40px;
}

.panelBlue {
  background-color: $secondary;
  color: $darker-blue;
  padding: 30px 40px;
}

.panelCongrats {
  background: $primary;
  color: white;
  padding: 100px 40px;
  font-size: 400px;

  & > h2 {
    text-align: center;
    font-size: clamp(2rem, 4vw, 4rem);
    font-weight: 900;
    margin-bottom: 2.5rem;
  }

  & > div {
    text-align: center;
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .panel {
    min-height: 600px;
  }

  .panelGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@include media-breakpoint-up(lg) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .panelCongrats {
    height: 840px;
  }
}
