@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.heroImage {
  height: 300px;
  width: 100%;
  z-index: -1;
}

.headline {
  font-size: 1.3rem;
  font-weight: 900;
}

.statsPanel {
  background-color: $secondary;
  padding: 22px 30px;
  text-align: center;
  height: 100%;

  & .number {
    font-size: 4rem;
  }

  & .list {
    margin-top: 1rem;
  }
}
