@import 'variables.scss';

.btn {
  position: relative;
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn-success {
  color: white;
}
