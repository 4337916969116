@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.title {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.panel {
  color: white;
  padding: 30px 40px;
  min-height: 200px;
  max-width: 100%;
}

.panelOrange {
  background: $primary;
  color: white;
  padding: 30px 40px;
}

.panelBlue {
  background-color: $secondary;
  color: $darker-blue;
  padding: 30px 40px;
}

.form {
  & * {
    color: white;
    font-weight: 900;
  }
}

@include media-breakpoint-up(lg) {
  .panel {
    min-height: 200px;
  }
}

@include media-breakpoint-up(lg) {
  .panelCongrats {
    height: 840px;
  }
}
