.container {
  position: relative;
  width: 100%;
  height: 40px;
}

.circle {
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: 26px;
  text-align: center;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 900;
}

.bar {
  height: 100%;
  border-top-right-radius: 26px;
  border-bottom-right-radius: 26px;
}

.barText {
  height: 100%;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  color: white;
}

.circle-green {
  background: #a3e670 0% 0% no-repeat padding-box;
}

.bar-green {
  background: transparent linear-gradient(180deg, #89ca58 0%, #78b341 100%) 0% 0%
    no-repeat padding-box;
}

.circle-orange {
  background: #fcc85a 0% 0% no-repeat padding-box;
}

.bar-orange {
  background: transparent linear-gradient(180deg, #f5ba3b 0%, #dba127 100%) 0% 0%
    no-repeat padding-box;
}

.circle-red {
  background: #ff747c 0% 0% no-repeat padding-box;
}

.bar-red {
  background: transparent linear-gradient(180deg, #e45961 0%, #cf4e5d 100%) 0% 0%
    no-repeat padding-box;
}
