@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

$meterHeight: 100px;
$borderRadius: 50px;

.meterContainer {
  position: relative;

  &.hasValue {
    height: calc($meterHeight * 2);
  }
}

.valueArrow {
  position: absolute;
  color: #000;
  text-align: center;
  top: calc($meterHeight + 1rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.meter {
  border-radius: $borderRadius;
  height: $meterHeight;

  .meterIncrement {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    overflow-wrap: anywhere;
    flex-basis: min-content;

    flex-grow: 1;
    color: #fff;
    font-weight: 600;
    font-size: clamp(12px, 2vw, 1rem);

    &:first-child {
      border-radius: $borderRadius 0 0 $borderRadius;
    }

    &:last-child {
      border-radius: 0 $borderRadius $borderRadius 0;
    }

    &.bordered {
      border-left: 1px solid #fff;
      &:first-child {
        border-left: none;
      }
    }

    & span {
      z-index: 1;
    }

    & .label {
      position: absolute;
      top: calc($meterHeight + 10px);
      width: 200%;
      color: $dark-blue;
      font-weight: 400;
      text-align: center;
    }
  }

  &.solid {
    background: $dark-blue;

    & .incrementInner {
      position: absolute;
      &.optimal {
        background-color: $success;
        width: 100%;
        height: 70%;

        &.firstOptimal {
          width: 70%;
          border-top-left-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
          left: auto !important;
          right: -1%;
        }

        &.lastOptimal {
          width: 70%;
          border-top-right-radius: $borderRadius;
          border-bottom-right-radius: $borderRadius;
          right: auto !important;
          left: -1%;
        }
      }
    }
  }

  &.vitaminC {
    background: transparent
      linear-gradient(
        90deg,
        #253931 0%,
        #295241 27%,
        #7c9852 53%,
        #b2d565 74%,
        #f8cc5e 100%
      )
      0% 0% no-repeat padding-box;
  }

  &.redYellowGreen {
    background: transparent
      linear-gradient(
        90deg,
        #df757f 0%,
        #e07c7b 17%,
        #e8ba58 33%,
        #9ccc79 50%,
        #88b761 100%
      )
      0% 0% no-repeat padding-box;
  }

  &.temperature {
    background: transparent
      linear-gradient(
        90deg,
        #df757f 0%,
        #e07c7b 17%,
        #e8ba58 33%,
        #9ccc79 50%,
        #9ccc79 67%,
        #b5d5dc 100%
      )
      0% 0% no-repeat padding-box;
  }

  &.oximeter {
    background: transparent
      linear-gradient(
        90deg,
        #df757f 0%,
        #e07c7b 17%,
        #e8ba58 33%,
        #9ccc79 50%,
        #88b761 100%
      )
      0% 0% no-repeat padding-box;
  }

  &.ph {
    background: transparent
      linear-gradient(90deg, #df757f 0%, #e8ba58 24%, #9acb71 50%, #b5d5dc 100%) 0% 0%
      no-repeat padding-box;
  }

  &.phDisplay {
    background: transparent
      linear-gradient(90deg, #df757f 0%, #e8ba58 24%, #9acb71 50%, #b5d5dc 100%) 0% 0%
      no-repeat padding-box;
  }
}
