@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.mainPanel {
  background-size: cover;

  & .title {
    font-size: 2rem;
    font-weight: 900;
    color: $dark-blue;
    margin-bottom: 4rem;
  }
}

.subPanel {
  background-color: rgba(0, 30, 60, 0.85);
  color: white;
  padding: 22px 30px;
  text-align: center;

  & .title {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.featurePanel {
  text-align: center;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    scale: 1.02;
  }

  & .title {
    font-size: clamp(1.7rem, calc(-0.875rem + 4vw), 3rem);
    font-weight: 900;
    color: white;
    margin-bottom: 0;
  }
}

.gradientButton {
  background: $background-gradient !important;
  border: none;

  &:after {
    content: '';
    background: #fff3;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
  }

  &:hover:after {
    opacity: 1;
  }
}

@include media-breakpoint-up(xl) {
  .mainPanel {
    background-size: auto;
  }

  .subPanel {
    padding: 44px 60px;
  }
}
