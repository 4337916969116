@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.masonryGrid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -4rem; /* gutter size offset */
  width: auto;
}

.masonryGridCol {
  padding-left: 4rem; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.masonryGridCol > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 4rem;
}

.title {
  color: $primary;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}

.panel {
  padding: 0;
}

.panelHeader {
  padding: 10px 40px 10px 40px;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: flex-end;

  & * {
    font-weight: 900;
  }
}

.panelContent {
  margin: 10px 30px 30px 30px;
  padding: 10px 10px 60px 10px;
}

@include media-breakpoint-up(lg) {
  .grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}
