@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

.main {
  height: 100%;
}

.sidenav {
  display: none;
  top: 0;
  bottom: 0;
  position: fixed;
  width: $drawerWidth;
  background-color: $dark-blue;
  color: #fff;
  padding-top: 120px;
  overflow-y: auto;

  & .sidenavIcon {
    margin-top: -12px;
    width: 80px;
  }

  & .sidenavItems {
    background-color: $dark-blue;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    font-weight: 800;
    cursor: pointer;

    & .sidenavItem {
      color: white;
      padding: 20px 20px 20px 40px;
      font-size: 1.2rem;

      &:hover {
        text-decoration: none;
        background-color: $darker-blue;
      }
    }

    & .sidenavSubMenu {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-out;
      display: flex;
      flex-direction: column;
      font-weight: 300;
      &.open {
        max-height: 1000px;
        transition: max-height 0.5s ease-in;
      }

      & .sidenavSubItem {
        padding: 0.5em 0 0.5em 4em;
        color: $luh-white;
      }
    }
  }
}

.mobileNavSubmenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  &.open {
    max-height: 100vh;
    transition: max-height 0.5s ease-in;
  }

  & .mobileNavSubmenuItem {
    padding-left: 2rem;
  }
}

.content {
  margin-left: 0;
}

.active {
  background-color: white !important;
}

.list-group-item.active {
  background-color: white !important;
}

.list-group-item .active {
  background-color: white !important;
}

.navbar {
  min-height: $topbarHeight;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-weight: 500;
  font-size: 1.2rem;
  background-color: $dark-blue;

  & .items {
    & > * {
      margin-left: 40px !important;
      padding-bottom: 4px !important;
    }
  }
}

.wrapper {
  min-height: calc(100vh - #{$footer-height});
}

.footer {
  height: $footer-height;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 1.5rem;

  & > div {
    margin-right: 1rem;
  }

  & a:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    background-color: white;

    & * {
      color: $dark !important;
    }
  }

  .sidenav {
    display: block;
  }

  .footer {
    margin-left: $drawerWidth;
  }

  .content {
    margin-left: $drawerWidth;
  }
}
