$drawerWidth: 340px;
$topbarHeight: 72px;
$heroHeaderHeight: 300px;
$primary: #db582a;
$red: #df767e;
$yellow: #f4ba3b;
$orange: #e3a82d;
$dark-blue: #001e3c;
$darker-blue: #00172e;
$navy: #071d31;
$luh-white: #f9f9f9;
$body-bg: #f5f5f5;
$bg-grey: #ededed;
$secondary: #b5d5dc;
$success: #9acb71;
$successText: white;
$blue: #5cc3d6;
$background-gradient: linear-gradient(90deg, $yellow, $primary);
$footer-height: 80px;
