@import '~bootstrap/scss/bootstrap.scss';

.panel {
  padding: 25px 40px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

@include media-breakpoint-up(lg) {
  .panel {
    padding: 50px 80px;
  }
}
