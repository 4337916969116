@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

:global {
  .recommendationPanelContainer {
    overflow-x: hidden;
    position: absolute;
    top: calc(($heroHeaderHeight / 2) + $topbarHeight + 7vh);
    transform: translate(0, -50%);
    width: clamp(270px, 50vh, 50%);
    right: 0;
    height: clamp(200px, 24vh, 300px);
  }

  .recommendationPanel {
    border-radius: 5px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    padding: 20px;
    right: 0px;
    font-size: 1.2em;
    & :local {
      animation: slideIn 1s ease;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translateX(0, -50%);
  }
}

.green {
  background: $success;
}

.yellow {
  background: $yellow;
}

.red {
  background: $red;
}

.blue {
  background: $blue;
}
