@import 'variables.scss';
@import '~bootstrap/scss/bootstrap.scss';

.hero {
  height: $heroHeaderHeight;
  display: flex;
  align-items: center;
  & h1 {
    font-size: clamp(2vh, 6rem, 5vh);
    letter-spacing: 0px;
  }
}
