@import 'variables.scss';

.meterLabels {
  margin-top: 1em;
  width: 100%;
  position: relative;
  height: 92px;
  text-align: center;

  & > .meterLabel {
    font-size: clamp(0.7em, 2vw, 1em);
    position: absolute;
    max-width: clamp(72px, 15vw, 200px);
    &.below {
      left: 0%;
      text-align: left;
    }

    &.standard {
      left: 35%;
    }

    &.optimal {
      left: 90%;
      text-align: right;
    }
  }
}
